import React from "react";
import desktop_wallpaper from "./Images/6.png"
import phone_wallpaper_1 from "./Images/1.png"
import phone_wallpaper_2 from "./Images/2.png"
import phone_wallpaper_3 from "./Images/3.png"
import phone_wallpaper_4 from "./Images/4.png"
import phone_wallpaper_5 from "./Images/5.png"
import phone_wallpaper_6 from "./Images/7.png"
import phone_wallpaper_7 from "./Images/8.png"
import phone_wallpaper_8 from "./Images/9.png"
import phone_wallpaper_9 from "./Images/10.png"

import { useState, useEffect } from "react";

const phone_images = [
  phone_wallpaper_1,phone_wallpaper_2,phone_wallpaper_3,phone_wallpaper_4,phone_wallpaper_5,phone_wallpaper_6,phone_wallpaper_7,phone_wallpaper_8,phone_wallpaper_9
];

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function App() {

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [usedImage, setUsedImage] = useState(null);

  const deadline = "March, 23, 2024";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    getTime(deadline)
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(()=>{

    if(window.innerHeight >= window.innerWidth)
    {
	setUsedImage(phone_images[getRandomInt(0, phone_images.length-1)]);
    }
    else
    {
	setUsedImage(desktop_wallpaper);
    }

  }, [window.innerHeight]);

  return (
	<>
		<head>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"></link>
		</head>
		<style>{`
			body {
				-ms-overflow-style: none;
				scrollbar-width: none;
			}

			body::-webkit-scrollbar {
				display: none;
 			}
		`}</style>
		<div className="flex flex-row inset-0 absolute">
			<div className="overflow-hidden absolute inset-0">
	  			<div className="overflow-hidden absolute inset-0" style={!usedImage ? {}:{ backgroundImage: "url(" + (usedImage ?? "") + ")", backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
	  			{/*<img alt="bg" src={usedImage} className="w-full h-full object-cover brightness-[0.8] contrast-[1.1]" />*/}
			</div>

			<div className="absolute inset-0 flex flex-col z-10 mt-[21vh] lg:mt-[70vh] px-4" style={{ textShadow: '4px 4px 8px #000000' }}>
				<div className="flex w-full justify-center">
					<h1 className="text-white text-4xl md:text-6xl lg:text-9xl text-center flex justify-center">
	  {/*					<span className="flex flex-col lg:w-[175px]"><p>{days.toString().padStart(2, '0')}</p><p className="text-sm">DAYS</p></span>
						:
						<span className="flex flex-col lg:w-[175px]"><p>{hours.toString().padStart(2, '0')}</p><p className="text-sm">HOURS</p></span>
						:
						<span className="flex flex-col lg:w-[175px]"><p>{minutes.toString().padStart(2, '0')}</p><p className="text-sm">MINUTES</p></span>
						:
						<span className="flex flex-col lg:w-[175px]"><p>{seconds.toString().padStart(2, '0')}</p><p className="text-sm">SECONDS</p></span> */}
						<div className="flex w-full justify-center ">
    							<h1 className="text-white text-4xl md:text-6xl lg:text-9xl text-center flex justify-center">
    							    <a href="/memories/index.html" className="inline-flex items-center px-4 py-2 bg-gray-500 text-white font-semibold text-xs uppercase rounded hover:bg-gray-700">
    							        <svg className="w-6 h-6 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
    							            <path d="M5 3l14 9-14 9V3z"></path>
    							        </svg>
    							    	Играть онлайн	
	  						    </a>
    							</h1>
						</div>
		
	  				</h1>
				</div>
{/* Footer */}
			</div>
		
		</div>	 
		<div className="absolute bottom-0 left-0 right-0 w-full bg-black justify-center items-center z-20">
			<div className="flex justify-center items-center text-white py-2">
				<a href="https://vk.com/brokenbondsthegame" className="mx-2">
					<i class="fab fa-vk"></i> VK
				</a>
				<a href="https://t.me/brokenbondsthegame" className="mx-2">
					<i class="fab fa-telegram"></i> Telegram
				</a>
				{/*<a href="404" className="mx-2">
					<i class="fab fa-steam"></i> Steam
				</a>*/}
			</div>
		</div>     
		{/*<div className="flex flex-row bottom-0 w-full h-full bg-black justify-center items-center overflow-hidden">
			<iframe frameborder="0" src="https://itch.io/embed/2389228"></iframe>
		</div>*/}
	</>
	);
}

export default App;
